<template>
  <div class="flex flex-col">
    <div class="mb-4 flex gap-4">
      <button @click="setRating(number)" v-for="number in 5" :key="number">
        <i v-if="rating >= number" class="bx bxs-star text-2xl"></i>
        <i v-if="rating < number" class="bx bx-star text-2xl"></i>
      </button>
    </div>

    <textarea
      v-model="comment"
      rows="6"
      cols="10"
      maxlength="400"
      class="bg-[#141417] rounded-lg px-4 pb-4 pt-4"
      style="resize: none"
    ></textarea>

    <vs-button @click="createComment" class="w-40 mr-0 ml-0 mt-4"
      >Submit</vs-button
    >
  </div>
</template>

<script>
import { createComment } from "@/services/ApiService";

export default {
  name: "AddComment",
  components: {},
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      comment: "",
      rating: 0,
    };
  },
  methods: {
    createComment() {
      createComment(this.product._id, {
        content: this.comment,
        rating: this.rating,
      })
        .then(() => {
          this.$vs.notification({
            color: "success",
            text: "Comment created",
          });
        })
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
        });
    },
    setRating(number) {
      this.rating = number;
    },
  },
};
</script>

<style scoped>

</style>

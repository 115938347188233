<template>
  <div v-if="game" class="w-full h-full flex justify-center">
    <div class="view-background-image" :style="headerBackgroundImage"></div>
    <div id="container" class="relative pt-12 w-full max-w-10xl">
      <div
        id="header"
        class="flex flex-col md:flex-row items-center justify-center text-left flex-row md:justify-between w-full items-center"
      >
        <div id="left-section" class="h-full flex flex-col justify-center">
          <h1 class="md:text-4xl text-2xl font-bold">
            {{ game.name }}
          </h1>
          <span
            class="text-neutral-content font-bold text-xl md:text-right text-center"
            >{{ products.length }} products.</span
          >
        </div>
        <div
          :style="headerImage"
          class="h-full bg-center aspect-video h-48 bg-no-repeat rounded-xl overflow-hidden"
        ></div>
      </div>

      <div id="products" class="mt-4" v-if="products.length">
        <vs-card-group class="overflow-hidden">
          <vs-card
            type="3"
            :key="product._id"
            v-for="product in products"
            @click="
              selectedProduct = null;
              selectedProduct = product;
            "
          >
            <template #title>
              <div class="flex flex-row items-center mb-2">
                <h3 class="font-bold">{{ product.name }}</h3>
                <vs-button
                  v-if="controlProductStatusAsBoolean(product)"
                  active-disabled
                  class="!text-white"
                  size="small"
                >
                  <span class="!text-white">{{
                    controlProductStatusAsString(product)
                  }}</span>
                </vs-button>
              </div>
            </template>
            <template #img class="w-full">
              <img
                class="h-40"
                :class="{ 'h-46': selectedProduct._id === product._id }"
                :src="getCover(product)"
                alt=""
              />
            </template>
            <template #text>
              <div class="-m-2 w-48 text-[12px]">
                <vs-button size="small" warn active-disabled border>
                  Seller:
                  <span class="font-bold ml-1"> {{ product.store.name }}</span>
                </vs-button>

                <vs-button
                  size="small"
                  active-disabled
                  :success="!product.private"
                  color="rgb(59,222,200)"
                  border
                >
                  {{ controlPrivateOrAllowInvite(product) }}
                </vs-button>
              </div>
            </template>
          </vs-card>
        </vs-card-group>
      </div>

      <div id="product-details" class="mt-12 pb-8" v-if="selectedProduct">
        <div class="flex xl:flex-row flex-col-reverse justify-between">
          <div class="xl:max-w-1/2 flex flex-col gap-4 mb-4">
            <h1 class="text-3xl font-bold m-1">
              {{ game.name }} · {{ selectedProduct.name }}
            </h1>
            <div class="flex flex-row justify-start">
              <vs-button
                class="m-1"
                floating
                :disabled="!Boolean(activePlan)"
                size="large"
                @click="
                  () => {
                    openModal();
                  }
                "
              >
                <i class="bx bx-cart-add"></i> Subscribe
              </vs-button>

              <vs-button
                v-if="hasSubscription || isOwner"
                floating
                size="large"
                warn
                @click="startCheat()"
              >
                <i class="bx bx-play-circle"></i> Play
              </vs-button>

              <vs-tooltip v-else>
                <vs-button
                  :disabled="Boolean(true)"
                  floating
                  size="large"
                  warn
                  @click="startCheat()"
                >
                  <i class="bx bx-play-circle"></i> Play
                </vs-button>
                <template #tooltip>
                  <span>You have no subscription</span>
                </template>
              </vs-tooltip>
            </div>

            <div>
              <h2 class="text-2xl mb-4">Supported Windows Versions</h2>
              <div class="flex gap-4 flex-wrap">
                <kbd
                  class="kbd kbd-md lg:kbd-lg"
                  :key="version"
                  v-for="version of supportedVersions"
                >
                  {{ version }}
                </kbd>
              </div>
            </div>

            <Comments
              v-if="enabled"
              :product="selectedProduct"
              :subscription="hasSubscription && !isOwner"
            ></Comments>
          </div>

          <div class="flex xl:justify-self-end justify-center mb-8">
            <div class="w-[640px] h-[360px] overflow-hidden rounded-xl">
              <Splide v-if="enabled" :options="{ rewind: true }">
                <SplideSlide
                  class="w-[640px] h-[360px]"
                  v-for="media in filteredMedias"
                  :key="media.id"
                  :data-splide-html-video="media.id"
                >
                  <iframe
                    v-if="media.type === mediaTypes.video"
                    width="100%"
                    height="100%"
                    :src="`https://www.youtube-nocookie.com/embed/${getVideoID(
                      media.url
                    )}?autoplay=0&mute=1&controls=0&showinfo=0&loop=1`"
                  >
                  </iframe>
                  <img
                    v-else
                    class="w-full h-full bg-center bg-cover"
                    :src="getImage(media)"
                    :alt="media.id"
                  />
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SubscribeProduct
      v-if="selectedProduct"
      :product="selectedProduct._id"
      :plans="selectedProduct.plans"
      :active.sync="subscribeActive"
    />

    <vs-dialog v-model="warningModal">
      <template #header>
        <h4>Warning</h4>
      </template>
      <div>
        <h1>
          Couldn't connect to loader, please make sure it's started or download
          it below.
        </h1>
      </div>
      <template #footer>
        <div class="footer-dialog flex justify-end">
          <vs-button @click="downloadLoader()"> Download</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import {
  controlSubscriptionByProduct,
  getFileByProduct,
  getGameById,
  getLoader,
  getPlansByProduct,
  getProductByGame,
} from "@/services/ApiService";
import SubscribeProduct from "@/views/user/game/Subscribe";
import { getSupportedVersions } from "@/core/utils.ts";
import Comments from "@/views/user/game/comments/Comments";

export default {
  name: "Game",
  components: { Comments, SubscribeProduct },
  data() {
    return {
      selectedProduct: null,
      products: [],
      game: null,
      subscribeActive: false,
      warningModal: false,
      enabled: true,
      activePlan: true,
      activePlayButton: false,
      hasSubscription: false,
      isOwner: false,
    };
  },
  computed: {
    headerBackgroundImage() {
      return `background-image: url("https://cdn.cloudflare.steamstatic.com/steam/apps/${this.game.steamID}/library_hero.jpg")`;
    },
    headerImage() {
      return `background-image: url("https://cdn.cloudflare.steamstatic.com/steam/apps/${this.game.steamID}/header.jpg")`;
    },
    mediaTypes() {
      return this.$store.state.mediaTypes;
    },
    filteredMedias() {
      return this.selectedProduct.medias.filter((media) => {
        return (
          media.type === this.mediaTypes.video ||
          media.type === this.mediaTypes.image
        );
      });
    },
    productStatus() {
      return this.$store.state.productStatus;
    },
    supportedVersions() {
      return getSupportedVersions(this.selectedProduct);
    },
  },
  methods: {
    downloadLoader() {
      getLoader("loader").then((response) => {
        const link = document.createElement("a");
        link.href = response.signedURL;
        link.download = "loader.exe";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getPlansByProduct() {
      getPlansByProduct(this.selectedProduct._id).then((response) => {
        if (
          this.selectedProduct.status === this.productStatus.Working ||
          this.selectedProduct.status === this.productStatus["In Development"]
        ) {
          this.selectedProduct.plans = response;
          this.activePlan = response.length > 0;
          return;
        }

        this.selectedProduct.plans = response;
        this.activePlan = false;
      });
    },
    controlProductStatusAsBoolean(product) {
      const productStatus = this.$store.state.productStatus;
      return (
        product.status === productStatus["In Development"] ||
        product.status === productStatus.Updating ||
        (product.private && product.allowUsersToInvite)
      );
    },
    controlProductStatusAsString(product) {
      const productStatus = this.$store.state.productStatus;
      if (product.status === productStatus["In Development"])
        return "In Development";

      const workingStatus = productStatus.Working;
      return product.status === workingStatus ? "Working" : "Updating";
    },
    getVideoID(url) {
      const regExp =
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
      const match = url.match(regExp);
      return match ? match[1] : null;
    },

    startCheat() {
      if (this.$socket.readyState !== 1) {
        this.warningModal = true;
        return;
      }
      if (!this.$store.state.version) {
        this.$vs.notification({
          title: "Warning",
          text: `We couldn't retrieve your windows version please try again.`,
          iconPack: "feather",
          color: "warning",
        });
        return;
      }

      const min = this.selectedProduct.minSupportedWinBuild;
      const max = this.selectedProduct.maxSupportedWinBuild;
      const version = this.$store.state.version;
      if (!(min === 0 && max === 0)) {
        if (version < min || version > max) {
          this.$vs.notification({
            title: "Warning",
            text: `Your windows version is not supported by this product.`,
            iconPack: "feather",
            color: "warning",
          });
          return;
        }
      }

      getFileByProduct(this.selectedProduct._id, "deleted")
        .then((deletedFileName) => {
          getFileByProduct(this.selectedProduct._id)
            .then((response) => {
              if (this.$socket.OPEN) {
                try {
                  this.$socket.sendObj({
                    type: "executable",
                    data: {
                      ...response,
                      oldFileName: deletedFileName,
                    },
                  });
                } catch (e) {
                  this.warningModal = true;
                  return;
                }
              } else {
                this.warningModal = true;
              }

              this.$vs.notification({
                color: "success",
                name: "Starting",
                text: "Starting Cheat!",
              });
            })
            .catch((err) => {
              if (err && err.response) {
                this.$vs.notification({
                  color: "danger",
                  name: "Error",
                  text: err.response.data.message,
                });
              }
            });
        })
        .catch((err) => {
          if (err && err.response) {
            this.$vs.notification({
              color: "danger",
              name: "Error",
              text: err.response.data.message,
            });
          }
        });
    },
    openModal() {
      this.subscribeActive = !this.subscribeActive;
    },
    getCover(product) {
      const cover = product.medias.find((media) => {
        return media.type === this.mediaTypes.cover;
      });

      return cover.url;
    },
    getImage(media) {
      return media.url;
    },
    controlPrivateOrAllowInvite(product) {
      return product.private ? "Invite only" : "Public";
    },
    controlSubscription() {
      controlSubscriptionByProduct(this.selectedProduct._id).then(
        (response) => {
          this.hasSubscription = response.hasSubscription;
          this.isOwner = response.isOwner;
        }
      );
    },
  },
  async mounted() {
    const loading = this.$vs.loading({
      target: this.$refs.table,
      background: "rgba(0, 0, 0, 0.6)",
      color: "primary",
      type: "points",
    });
    this.gameId = this.$route.params.id;

    await getGameById(this.gameId).then((response) => {
      this.game = response;
    });

    await getProductByGame(this.gameId)
      .then((response) => {
        this.products = response;
        this.selectedProduct = this.products[0];
      })
      .catch((error) => {
        this.$vs.notification({
          position: "bottom-right",
          name: "Error",
          color: "danger",
          text: error.response.data.message,
        });
      });
    loading.close();
  },
  watch: {
    async selectedProduct() {
      this.subscribeActive = false;
      this.enabled = false;
      await this.$nextTick();

      this.enabled = true;
      this.getPlansByProduct();
      this.controlSubscription();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .vs-card {
    max-width: 500px !important;

    .vs-card__img {
      width: 600px !important;
    }
  }
}
</style>

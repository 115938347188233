<template>
  <div class="center">
    <vs-dialog v-model="active2">
      <template #header>
        <div class="flex flex-col">
          <h4>Select Plan</h4>
        </div>
      </template>
      <div class="flex flex-col gap-4">
        <p class="text-xs">
          Please make sure cheat supports your windows version.
        </p>
        <div class="flex flex-col w-full">
          <vs-button
            v-for="plan of plans"
            border
            warn
            :disabled="plan.fulfilledSlots >= plan.slot"
            :active="selectedPlan === plan._id"
            @click="selectedPlan = plan._id"
            :key="plan._id"
          >
            <div
              class="flex flex-row gap-2 items-center w-full justify-between"
            >
              <div>
                <span class="font-bold">{{ plan.name }} </span>
                <span>({{ plan.fulfilledSlots }}/{{ plan.slot }}) </span>
              </div>
              <div>
                <span class="font-bold"
                  >{{ plan.price === 0 ? "free" : `${plan.price}$`
                  }}<sup class="text-secondary"
                    >{{ calculateCheaperPercentage(plan) }}%</sup
                  >
                </span>
                <span></span>
              </div>
            </div>
          </vs-button>
          <span v-if="!plans.length">No plan found for the product</span>
        </div>
      </div>
      <template #footer>
        <div class="footer-dialog">
          <vs-button
            :disabled="selectedPlan === ''"
            @click="onSubscribeClick()"
            block
          >
            Subscribe
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { createSubscription } from "@/services/ApiService";
import { syncUser } from "@/services/UserService";

export default {
  name: "SubscribeProduct",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    product: {
      type: String,
      default: "",
    },
    plans: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedPlan: "",
    active2: false,
  }),
  methods: {
    getPlansForProduct() {
      this.plans.sort((a, b) => {
        return a.price - b.price;
      });
    },
    onSubscribeClick() {
      createSubscription(this.selectedPlan)
        .then(() => {
          this.$emit("close");
          this.$vs.notification({
            name: "Success",
            text: "Subscription created successfully",
            color: "success",
            position: "bottom-right",
          });
          this.$emit(`update:active`, false);
          syncUser();
        })
        .catch((error) => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
          this.$emit(`update:active`, false);
        });
    },
    calculateCheaperPercentage(plan) {
      const cheaperPlan = this.plans.find((p) => p.price < plan.price);
      if (cheaperPlan) {
        return Math.round(
          ((cheaperPlan.price - plan.price) / plan.price) * 100
        );
      }
      return 0;
    },
  },
  watch: {
    active2() {
      this.$emit("update:active", this.active2);
    },
    active() {
      this.active2 = this.active;
      this.getPlansForProduct();
    },
  },
};
</script>

<template>
  <div>
    <div class="rounded-xl">
      <h1 id="comment-page-title" class="font-bold mb-4 text-3xl">Comments</h1>

      <AddComment v-if="subscription" :product="product"></AddComment>
      <div class="flex flex-col gap-6 mt-4 w-full" id="comments">
        <CommentCard
          v-for="(comment, index) in comments"
          :key="index"
          :username="comment.user.username"
          :content="comment.content"
          :createdAt="comment.createdAt"
          :rating="comment.rating"
        ></CommentCard>
      </div>
    </div>
  </div>
</template>

<script>
import CommentCard from "@/views/user/game/comments/CommentCard";
import AddComment from "@/views/user/game/comments/AddComent";
import { getCommentsByProduct } from "@/services/ApiService";

export default {
  name: "Comments",
  components: { AddComment: AddComment, CommentCard },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
    subscription: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    comments: [],
    page: 0,
  }),
  methods: {
    getComments() {
      getCommentsByProduct(this.product._id, this.page)
        .then((res) => {
          this.comments = res;
        })
        .catch((err) => {
          this.$vs.notification({
            color: "danger",
            title: "Error",
            text: err.message,
          });
        });
    },
  },
  created() {
    this.getComments();
  },
};
</script>

<template>
  <div class="flex flex-col">
    <div class="block">
      <div class="flex justify-between">
        <div class="flex justify-center items-center">
          <span class="text-lg font-bold">{{ username }}</span>

          <span class="text-[#808080] mx-2">•</span>
          <span class="text-sm text-[#808080]">{{
            unixToDate(createdAt)
          }}</span>
        </div>
        <Stars class="ml-2" :rating="rating" />
      </div>

      <div id="comment" class="mt-6 break-words">
        <p>
          {{ content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from "@/components/Starts";

export default {
  name: "CommentCard",
  components: { Stars },
  props: {
    username: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    createdAt: {
      type: Number,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
  },
  methods: {
    unixToDate(unix) {
      return new Date(unix * 1000).toLocaleDateString();
    },
  },
};
</script>

<template>
  <div>
    <i
      v-for="i in 5"
      :key="i"
      class="text-lg"
      :class="{
        'bx bxs-star ': i <= rating,
        'bx bx-star': i > rating,
      }"
    ></i>
  </div>
</template>

<script>
export default {
  name: "stars",
  props: {
    rating: {
      type: Number,
      required: true,
    },
  },
};
</script>
